import { ArticleMedia } from 'designsystem/components/2-molecules/Article';
import { aspectRatioAsNumber } from 'designsystem/utilities/aspectRatioAsNumber';
import { imageWidthConverter } from 'designsystem/utilities/imageWidthConverter';
import { BackgroundContainerFeature } from 'features/Modules/BackgroundContainer';
import { useEffect, useState } from 'react';
import { useCentralStore } from 'store';
import { ImageFeature } from '../Image/ImageFeature';

export const ImageContentFeature = ({ content, settings, noBackground = false, spacing = true }: Content.ImageContent) => {
	const { caption, picture } = content?.properties ?? {};
	const { fullImage = false, aspectRatio, width, customWidth, position, noTopMargin, hideImageOnSmallScreens } = settings?.properties ?? {};
	const { isMobile } = useCentralStore();
	const { image, altText } = picture[0]?.content?.properties ?? {};
	const {
		focalPoint,
		properties: { umbracoHeight, umbracoWidth },
		url,
	} = image ?? {};

	// Picture handling start
	const [cropImage, setCropImage] = useState<boolean>(false);
	const [cropImageAspectRatio, setCropImageAspectRatio] = useState<number>(0);
	const [cropImageHeight, setCropImageHeight] = useState<number>(0);
	const [cropImageWidth, setCropImageWidth] = useState<number>(0);

	const imageWidth = width === 'Custom' ? undefined : width === null || width === '' ? 'Medium' : width;
	const displayedWidth = parseInt(customWidth) > 0 ? parseInt(customWidth) : imageWidthConverter(imageWidth);
	const selectedAspectRatio = aspectRatio !== null ? aspectRatio : fullImage === 'True' ? null : '16:9';

	useEffect(() => {
		const imageAspectRatio = parseInt(umbracoHeight) / parseInt(umbracoWidth);

		if (!selectedAspectRatio) {
			setCropImageAspectRatio(imageAspectRatio);
		} else if (imageAspectRatio !== aspectRatioAsNumber(selectedAspectRatio)) {
			setCropImage(true);
			setCropImageAspectRatio(aspectRatioAsNumber(selectedAspectRatio));
		}
	}, [selectedAspectRatio, umbracoHeight, umbracoWidth]);

	useEffect(() => {
		if (!cropImage) return;
		let calculatedWidth = displayedWidth;

		if (parseInt(umbracoWidth) < calculatedWidth) {
			calculatedWidth = parseInt(umbracoWidth);
		}

		let calculatedHeight = calculatedWidth * aspectRatioAsNumber(selectedAspectRatio);

		if (calculatedHeight > parseInt(umbracoHeight)) {
			calculatedHeight = parseInt(umbracoHeight);
			calculatedWidth = calculatedHeight / aspectRatioAsNumber(selectedAspectRatio);
		}

		setCropImageWidth(calculatedWidth);
		setCropImageHeight(calculatedHeight);
	}, [cropImage, displayedWidth, selectedAspectRatio, umbracoHeight, umbracoWidth]);
	// Picture handling end

	return hideImageOnSmallScreens && isMobile ? null : (
		<BackgroundContainerFeature enableBackground={!noBackground} noTopMargin={noTopMargin}>
			<ArticleMedia
				caption={caption}
				image={
					<ImageFeature
						altText={altText}
						aspectRatio={cropImageAspectRatio}
						cropImage={cropImage}
						customWidth={parseInt(customWidth)}
						focalPoint={focalPoint}
						height={cropImageHeight}
						hideImageOnSmallScreens={hideImageOnSmallScreens}
						isCover={cropImage}
						sizes={`${displayedWidth}px`}
						url={url}
						width={cropImageWidth}
					/>
				}
				width={imageWidth}
				position={!position ? 'Left' : position}
				customWidth={customWidth}
				spacing={spacing}
			/>
		</BackgroundContainerFeature>
	);
};
