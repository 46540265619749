export const imageWidthConverter = (height: UI.ImageWidth) => {
	if (!height) return 0;
	switch (height) {
		case 'XSmall':
			return 150;
		case 'Small':
			return 300;
		case 'Medium':
			return 400;
		case 'Large':
			return 600;
		case 'TextWidth':
			return 752;
		default:
			return 0;
	}
};
