import { Picture2 } from 'designsystem/components/1-atoms/Media/Picture2/Picture2';
import { useCentralStore } from 'store';

export interface ImageFeatureProps {
	altText: string;
	aspectRatio?: number;
	cropImage?: boolean;
	customWidth?: number;
	focalPoint?: { left: number; top: number };
	height?: number;
	heightClassName?: UI.ImageHeight;
	hideImageOnSmallScreens?: boolean;
	imageClassName?: string;
	isCover?: boolean;
	isFullWidth?: boolean;
	pictureHeight?: number;
	sizes?: string;
	url: string;
	width?: number;
}

export const ImageFeature = ({
	aspectRatio,
	hideImageOnSmallScreens,
	imageClassName,
	isCover,
	isFullWidth,
	pictureHeight,
	...props
}: ImageFeatureProps) => {
	const { isMobile } = useCentralStore();

	// remove aspectRatio from extra property?
	return hideImageOnSmallScreens && isMobile ? null : (
		<Picture2
			{...props}
			isCover={isCover}
			aspectRatio={aspectRatio}
			imageClassName={imageClassName}
			isFullWidth={isFullWidth}
			pictureHeight={pictureHeight}
		/>
	);
};
